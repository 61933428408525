import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

type LoadingSpinnerProps = {
  small?: boolean
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ small }) => (
  <Box textAlign="center" className={small ? 'h-5' : 'h-10'}>
    <CircularProgress size={small ? 20 : 40} />
  </Box>
)
export default LoadingSpinner
